@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden !important;
  /* background-color: #000000; */
  max-width: 100vw !important;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 0 10%;
}
.Hire-main {
  background-image: url("./assets/images/Hire-bg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.navLink a {
  display: inline-block;
  position: relative;
  color: black;
  text-decoration: none;
}
.navLink a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  border-radius: 5px;
  color: #ffe38a;
  height: 0.09em;
  bottom: 0;
  left: 0;
  background: currentcolor;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.navLink a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* .slick-dots {
  bottom: 20px;
}

.slick-dots li {
  margin: 0;
}

.custom-dot {
  display: inline-block;
  margin-right: 10px; 
}

.custom-dot span {
  width: 10px; 
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #fff; 
  transition: all 0.3s ease;
}
.slick-dots li {
  display: flex;
  justify-content: ;
  align-items: center;
  align-content: center;
  padding: 10px;
  margin: 30px !;
  border-radius: 50%; 
  padding: 10px !important;
  border: 1px solid rebeccapurple;
}
.slick-dots li div{
  margin: 10px ;
  border: 1px solid black ;
  background-color: red;
}
*/

.slick-dots li.slick-active div {
  background-color: #fdd835;
  border-color: #fdd835;
}

/* .slick-prev, .slick-next {
  display: none;
  color: #000000 !important;
}

.slick-slider:hover .slick-prev,
.slick-slider:hover .slick-next {
  display: block !important;
  color: red !important;
} */



/* ScrollToTopButton.css */
.scroll-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 50px;
  width: 50px;
  z-index: 4;
  padding: 10px;
  color: #fff !important;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  box-shadow: 2px 4px 4px black;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 5px 5px 10px rgb(223, 213, 213),
    inset 1px 1px 50px rgb(223, 213, 213);
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.modal-content-container {
  position: relative;
}

.modal-header {
  position: sticky;
  top: 0;
  /* background-color: #f8f9fa; */
  /* padding: 10px; */
  text-align: right;
  z-index: 1; /* Ensure the header stays above other content */
}

.modal-close {
  border-radius: 20px;
  /* background-color: gray; */
  font-size: 20px;
  cursor: pointer;
  border: none;
  color: #000000;
}

.modal-close:hover {
  color: red;
}
/* .modal-close {


  background-color: gray;
  /* border-radius: 100%; */
/* font-size: 20px; */
/* padding: 0px 10px;  */
/* cursor: pointer; */
/* border: none; */
/* color: #000000; */
/* } */
/* .modal-close:hover { */
/* color: red; */
/* } */
::-webkit-progress-value {
  background-color: #ffe48a;
  border-radius: 999px;
  
}
::-webkit-progress-bar {
  background-color: #e8ecef;
  border-radius: 999px;
}
.client-name {
  color: black !important;
}
/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: gray; /* Thumb color */
  border-radius: 2px; /* Border radius to round the thumb */
  height: 100px;
}

/* For Internet Explorer */
::-ms-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-ms-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-ms-scrollbar-thumb {
  background-color: #ffe48a; /* Thumb color */
  border-radius: 6px; /* Border radius to round the thumb */
}


@media screen and (max-width: 1023px) {
  
  html,
  body{
    overflow-x: hidden !important;
  }

}